<template>
  <div
    class="touchpad-wrapper"
    :class="{
      touched: isTouched,
    }"
  >
    <div class="touchpad-container">
      <div
        ref="touchpad"
        class="touchpad"
        :class="`${shape}-shape`"
        :style="style"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="handleTouchEnd"
      >
        <div
          v-if="isTouched"
          class="touch"
          ref="touchIndicator"
          :style="{
            backgroundColor: 'var(--userColor)',
          }"
        ></div>
      </div>
      <div class="jButtons" v-if="buttons > 1">
        <div
          v-for="n in buttons"
          :key="`btn-${n}`"
          class="button"
          @touchstart="buttonChange(n, true)"
          @touchend="buttonChange(n, false)"
        >
          {{ ['A','B','C'][n-1] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    centerColor: {
      type: String,
      default: "#fafafa",
    },
    buttons: {
      type: Number,
      default: 0,
    },
    shape: {
      type: String,
      default: "square",
    },
  },
  data() {
    return {
      x: 0,
      y: 0,
      angle: 180,
      ball: 0.15,
      speed: 0,
      radians: 0,
      size: 400,
      halfSize: 200,
      offset: 0,
      isTouched: false,
      indicatorHalfWidth: null,
    };
  },
  computed: {
    style() {
      return {
        "--size": `${this.ball * 100}%`,
        "--speed": `${this.speed}px`,
        "--offcenter": `${this.speed}px`,
        "--angle": `calc(${this.angle}deg - 90deg)`,
        "--centerColor": this.centerColor,
        "--x": `${Math.round(this.x * this.halfSize * (1 - this.ball))}px`,
        "--y": `${Math.round(this.y * this.halfSize * (1 - this.ball))}px`,
      };
    },
  },
  methods: {
    handleTouchStart({ touches: [touch] }) {
      this.$emit("touchStart");
      this.isTouched = true;
      this.handleTouchMove({ touches: [touch] });
    },
    handleTouchMove({ touches: [touch] }) {
      if (!this.isTouched) return;

      const { clientX, clientY } = touch;
      const { left, top, width, height } = this.$refs.touchpad.getBoundingClientRect();

      // Calculate relative coordinates within the touchpad
      let x = clientX - left - width / 2;
      let y = clientY - top - height / 2;

      this.updatePosition(x, y);
    },
    handleTouchEnd() {
      this.$emit("touchRelease");
      this.isTouched = false;
    },
    updatePosition(x, y) {
      if (this.shape == "circle") {
        this.radians = Math.atan2(y, x);
        this.speed = Math.min(Math.hypot(x, y), this.offset);
        const angle = Math.round((this.radians * 180) / Math.PI, 4);
        this.angle = angle + (angle > 180 ? -180 : 90);
        const factor = this.speed > this.offset ? this.offset / this.speed : 1;
        this.x = factor * x;
        this.y = factor * y;
      } else {
        const width = this.halfSize * (1 - this.ball);
        this.x = Math.min(Math.max(x / width, -1), 1);
        this.y = Math.min(Math.max(y / width, -1), 1) + 0;
      }
      this.emitAll();
    },
    buttonChange(button, pressed) {
      this.$emit("buttonchange", {
        button: button - 1,
        pressed: pressed,
      });
    },
    emitAll(name = "leverchange") {
      this.angle = this.angle - 90;

      this.$emit(name, {
        angle: this.angle,
        radians: this.radians,
        x: this.x,
        y: this.y,
        speed: this.normalize(this.speed, 0, this.halfSize * (1 - this.ball)),
      });
    },
    normalize(val, min, max) {
      return (val - min) / (max - min);
    },
    resetTouchpad() {
      const touchEl = this.$refs.touchpad;
      this.size = touchEl.offsetWidth;
      this.halfSize = this.size * 0.5;
      this.offset = this.size * (1 - this.ball) * 0.5;
      this.speed = 0;
      this.x = 0;
      this.y = 0;
    },
  },
  mounted() {
    this.resetTouchpad();
    window.addEventListener("resize", this.resetTouchpad);
    this.emitAll();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resetTouchpad);
  },
};
</script>

<style scoped src="./TouchPad.scss" lang="scss"></style>
